@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f5f5f5;
  padding-right: 0 !important;
}

header {
  padding-right: 0 !important;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

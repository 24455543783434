.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots li.slick-active button:before {
  color: #1893c6;
}

.customizeArrow .slick-prev {
  left: 40px;
  z-index: 1000;
}

.customizeArrow .slick-next {
  right: 40px;
  z-index: 1000;
}

.map-container {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 70vh;
}

.ant-notification-notice {
  margin-top: 50px;
}
